import Modernizr from 'modernizr';
import $ from 'jquery-slim';

class OptProductCarousel
{
    constructor()
    {
        $(document).ready(this.init.bind(this));
    }

    init()
    {
        this.$carousel = $('#productCarousel');

        this.$items = this.$carousel.find('.carousel-item');
        this.length = this.$items.length;

        this.$items.removeClass('active');
        this.$items.eq(0).addClass('active');

        this.itemsPerSlide = 4;

        let mq = window.matchMedia('(max-width: 767.98px)');
        mq.addListener(this.change.bind(this));
        this.change(mq);


        if (this.length < 4) this.$carousel.addClass('no-ui');


        if (Modernizr.touch) this.$carousel.swipe({swipe:this.swipe.bind(this), allowPageScroll:'vertical'});

        this.$carousel.on('slide.bs.carousel', this.prepend.bind(this));
        this.$carousel.on('slid.bs.carousel', this.append.bind(this));
    }

    change(mq)
    {
        this.itemsPerSlide = mq.matches ? 2 : 4;
    }

    swipe(event, direction,  distance, duration, fingerCount, fingerData)
    {
        if (event.cancelable) event.preventDefault();
        if (direction === 'left') this.$carousel.carousel('next');
        if (direction === 'right') this.$carousel.carousel('prev');
    }

    prepend(event)
    {
        if (event.direction === 'right') this.$carousel.find('.carousel-item').eq(this.length - 1).prependTo('#productCarousel .carousel-inner');
    }

    append(event)
    {
        if (event.direction === 'left') this.$carousel.find('.carousel-item').eq(0).appendTo('#productCarousel .carousel-inner');
    }

}

const carousel = new OptProductCarousel();






